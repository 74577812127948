<!-- 预测性维护-机器学习模块管理器 -->
<template>
    <div style="width:100%;height:100%">
        <!-- <iframe style="width:100%;height:100%" src="https://www.hik-cloud.com/safe-center/index.html#/login/retail?type=true&direction=%2Fvideo%2Fscene" frameborder="0"></iframe> -->
        <div class="linkTo">
            正在跳转至新页面，请稍后...
        </div>
    </div>
</template>
<script>
    import {
        productionInforTeamSelect,
        productionInforEquipmentOutputByDayQuery,

    } from "@/api/api_productionManagement";
    export default {
        data() {
            return {

            }
        },
        
        created (){
            this.open(); 
        },
       
        methods: {
            open(){
            window.open('http://47.104.99.213:8080/Thingworx/Runtime/index.html#mashup=SDKJ.AnalysisServices.AnalysisMainMashup&appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
        },
        }
    }
</script>

<style scoped>
   
</style>
<style lang="less" scoped>
    .linkTo{
        width: 100%;
        height: 100%;
        font-size: 30px;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        align-content: center;
    }
</style>